<template>
  <v-card class="pa-3" flat>
    <v-card-title v-if="validJWT" class="clav-content-title-1">
      <span v-if="temPass">Alteração</span>
      <span v-else>Definição</span>
      de password
    </v-card-title>
    <v-card-text v-if="validJWT">
      <v-form ref="form" lazy-validation>
        <v-text-field
          prepend-icon="person"
          label="Nome"
          type="text"
          v-model="form.name"
          disabled
        />
        <div v-if="temPass">
          <v-text-field
            id="password"
            prepend-icon="lock"
            name="password"
            v-model="form.atualPassword"
            label="Password Atual"
            type="password"
            :rules="regraAtualPassword"
            required
          />
        </div>
        <v-text-field
          id="password"
          prepend-icon="lock"
          name="password"
          v-model="form.password"
          label="Nova Password"
          type="password"
          :rules="regraPassword"
          @input="verificaPassword()"
          required
        />
        <v-text-field
          id="rep_password"
          prepend-icon="lock"
          name="rep_password"
          v-model="form.rep_password"
          label="Repita a Password"
          type="password"
          :rules="regraPassword"
          @input="verificaPassword()"
          required
        />
      </v-form>
    </v-card-text>
    <v-card-actions v-if="validJWT">
      <v-spacer></v-spacer>
      <v-btn color="primary" type="submit" rounded @click="alterarPW">
        <span v-if="temPass">Alterar</span>
        <span v-else>Definir</span>
        password
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :color="color" :timeout="timeout" :top="true">
      {{ text }}
      <v-btn text @click="fecharSnackbar">Fechar</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: "signup",
  mounted: async function () {
    var user = this.$verifyTokenUser();
    this.form.id = user.id;

    var res = await this.$request("get", "/users/" + this.form.id);
    this.form.name = res.data.name;
    this.temPass = res.data.temPass;
    this.validJWT = true;
  },
  data() {
    return {
      regraAtualPassword: [(v) => !!v || "Password atual é obrigatório."],
      regraPassword: [(v) => !!v || "Password é obrigatório."],
      form: {
        name: "",
        atualPassword: "",
        password: "",
        rep_password: "",
      },
      validJWT: false,
      snackbar: false,
      color: "",
      done: false,
      timeout: 4000,
      text: "",
      temPass: false,
    };
  },
  methods: {
    verificaPassword() {
      if (this.form.password != this.form.rep_password) {
        if (this.regraPassword.length == 1) {
          this.regraPassword = this.regraPassword.concat(["A password deve ser igual!"]);
        }
      } else {
        if (this.regraPassword.length == 2) {
          this.regraPassword = this.regraPassword.slice(0, 1);
        }
      }
    },
    alterarPW() {
      if (this.$refs.form.validate()) {
        var obj;
        if (this.temPass) {
          obj = {
            atualPassword: this.form.atualPassword,
            novaPassword: this.form.password,
          };
        } else {
          obj = { novaPassword: this.form.password };
        }
        this.$request("put", "/users/" + this.form.id + "/password", obj)
          .then((res) => {
            this.text = res.data;
            this.color = "success";
            this.snackbar = true;
            this.done = true;
          })
          .catch((err) => {
            this.text =
              "Ocorreu um erro ao atualizar a sua password. Introduziu corretamente a password atual?";
            this.color = "error";
            this.snackbar = true;
            this.done = false;
          });
      } else {
        this.text = "Por favor preencha todos os campos!";
        this.color = "error";
        this.snackbar = true;
        this.done = false;
      }
    },
    fecharSnackbar() {
      this.snackbar = false;
      if (this.done == true) this.$router.push("/");
    },
  },
};
</script>
