<template>
  <AlteracaoPassword />
</template>

<script>
import AlteracaoPassword from "@/components/users/AlteracaoPassword.vue";

export default {
  components: {
    AlteracaoPassword
  }
};
</script>
